img{
  max-width: 100%;
}

@mixin generateSpacings($prefix, $property) {
  $sizes: [0, 0.25, 0.5, 1, 1.5, 3, 5];
  $spacing: 1rem;

  @for $i from 1 through length($sizes) {
    $size: nth($sizes, $i);
    .#{$prefix}-#{$i - 1} {
      #{$property}: $spacing * $size !important;
    }
  }
}

$spacingTypes: [
[m, margin],
[mt, margin-top],
[mr, margin-right],
[mb, margin-bottom],
[ml, margin-left],
[p, padding],
[pt, padding-top],
[pr, padding-right],
[pb, padding-bottom],
[pl, padding-left]
];

@each $item in $spacingTypes {
  @include generateSpacings(nth($item, 1), nth($item, 2));
}

// grid
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
div[class^='col-'], div[class*=' col-']{
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-md-4{
  @media (min-width: $md){
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}
.col-md-6{
  @media (min-width: $md){
    flex: 0 0 50%;
    max-width: 50%;
  }
}



// auto
.m-auto{
  margin: auto;
}

// positions

.p-relative{
  position: relative;
}

// container
.container{
  width: 100%;
  //padding: 1.5rem;
  margin: auto;
  max-width: 1600px;
}

// flex
.d-flex{
  display: flex;
}
.col-auto{
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}
.flex-fill{
  flex: 1 1 auto;
}
.align-item-center{
  align-items: center;
}
.reverse-md{
  @media (min-width: $md) {
    flex-direction: row-reverse;
  }
}

.table-like{
  .row{
    align-items: center;
    border-bottom: 1px solid #f0f3ff;
  }
}

// grid
.grid{
  display: grid;
  &-element{
    background-size: cover;
    background-position: center;
  }
}

// align
.text-align{
  &-left{
    text-align: left;
  }
  &-right{
    text-align: right;
  }
}

.w-100{
  width: 100%;
}

.w-50{
  width: 50%;
}

// text
.ellipsis{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pointer{
  cursor: pointer;
}

// forms

.form-control{
  display: block;
  margin-bottom: 1rem;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
input.form-control{
  height: calc(1.5em + .75rem + 2px);
}
