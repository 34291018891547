@import url('https://fonts.googleapis.com/css?family=Poppins:400,500&display=swap');

@font-face {
  font-family:"spartan-extra-bold";
  src:url("~assets/fonts/spartan-mb/SpartanMB-Extra-Bold.otf") format("opentype");
  font-display: swap;
  font-style:normal;
  font-weight:800;
}

@font-face {
  font-family:"spartan-bold";
  src:url("~assets/fonts/spartan-mb/SpartanMB-Bold.otf") format("opentype");
  font-display: swap;
  font-style:normal;
  font-weight:600;
}

@font-face {
  font-family:"spartan-semi-bold";
  src:url("~assets/fonts/spartan-mb/SpartanMB-SemiBold.otf") format("opentype");
  font-display: swap;
  font-style:normal;
  font-weight:600;
}

@font-face {
  font-family:"spartan";
  src:url("~assets/fonts/spartan-mb/SpartanMB-Regular.otf") format("opentype");
  font-display: swap;
  font-style:normal;
  font-weight:400;
}

.spartan{
  font-family: 'spartan', "Helvetica Neue", sans-serif;
}
.spartan-semi-bold{
  font-family: 'spartan-semi-bold', "Helvetica Neue", sans-serif;
}
.spartan-bold{
  font-family: 'spartan-bold', "Helvetica Neue", sans-serif;
}
.spartan-extra-bold{
  font-family: 'spartan-extra-bold', "Helvetica Neue", sans-serif;
}
