@import "/assets/styles/variables.scss";
@import "/assets/styles/fonts.scss";
@import "/assets/styles/utilities.scss";
@import "/assets/styles/ui/index";

* { box-sizing: border-box }

body{
  color: $primary-light;
  min-height: 100vh;
  //background: rgb(235,238,255);
}

body,button,input,textarea{
  @extend .spartan;
}

a{
  @extend .spartan-bold;
  color: $primary-dark;
  text-decoration: none;
}

h1{
  @extend .spartan-extra-bold;
  color: $primary-dark;
  font-size: 2.3rem;
  line-height: 3.3rem;
}

h2{
  color: $primary-dark;
}
