@import "assets/styles/variables";

.Main{
  overflow: hidden;
  section p{
    padding: 5rem;
  }
  section:nth-child(1) p{
    padding: 0;
  }
  &-section1{
    //background: linear-gradient(0deg, rgba(235,238,255,1) 0%, rgba(235,238,255,1) 50%, white 100%);
    background: linear-gradient(-14deg, #ebeeff 0%, #ebeeff 50%, white 50%);
    min-height: calc(100vh - 88px);
    justify-content: space-between;
    align-items: center;
    @media (min-width: $xxl) {
      min-height: 80vh
    }
    &-description.col-md-6{
      padding-top: 2rem;
      padding-right: 5rem;
      padding-left: 5rem;
      padding-bottom: 5rem;
      @media (min-width: $md) {
        padding: 5rem;
        margin-bottom: auto;
      }
    }
  }
  &-welcome{
    display: flex;
    align-items: center;
    &-img{
      width: 100%;
      max-height: 730px;
      //margin-bottom: 5rem;
      //@media (min-width: $md) {
      //  margin-bottom: auto;
      //}
    }
  }
  &-tech{
    width: 1rem;
    margin-right: 1rem;
  }

  #hunt{
    color: #656da2;
    background: rgb(235,238,255);
    img{
      width: 100%;
    }
    >div>div:nth-child(1){
      font-size: 0;
    }
  }
  #bench{
    background: linear-gradient(166deg, #ebeeff 28%, white 0);
  }
  #steps {
    .container {
      & > div {
        display: flex;
        flex-wrap: wrap;
        text-align: center;
        flex-direction: column;
        width: 100%;

        & > img, & > div {
          width: 200px;
          margin: auto;
        }

        .steps-scan-img {
          max-height: 150px;
        }
      }
    }
  }
  #contact{
    margin-top: 4rem;
    width: 100%;
    padding: 10% 25%;
    background: $primary-dark;
  }
}
