@keyframes fadeIn {
  0% {
    transform: translateY(10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
.fade-in{
  opacity: 0;
  //animation-delay: .5s;
  animation-duration: 2s;
  animation-name: fadeIn;
  animation-fill-mode: forwards;
}

@mixin generateDelays() {
  @for $i from 1 through 15 {
    $unit: .5s;
    .delay-#{$i - 1} {
      animation-delay: $unit * $i;
    }
  }
}

@include generateDelays();




