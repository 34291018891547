@import url(https://fonts.googleapis.com/css?family=Poppins:400,500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Header {
  overflow: hidden;
  font-family: 'spartan', "Helvetica Neue", sans-serif; }
  @media (min-width: 768px) {
    .Header {
      display: flex;
      justify-content: space-between; } }
  .Header-logo {
    background: #333976; }
    @media (min-width: 768px) {
      .Header-logo {
        background: transparent;
        margin: 1rem; } }
    .Header-logo-img {
      height: 46px;
      margin: 2rem 0 2rem 3rem; }
      @media (min-width: 768px) {
        .Header-logo-img {
          display: none; } }
    .Header-logo-img-primary {
      height: 46px;
      margin-left: 3rem; }
      @media (max-width: 768px) {
        .Header-logo-img-primary {
          display: none; } }
  .Header ul, .Header li {
    display: inline; }
  .Header ul {
    padding-left: 0;
    width: 100%;
    margin-top: 0;
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin-right: 2rem;
    list-style-type: none;
    display: flex;
    justify-content: space-evenly;
    background: #ebeeff; }
    @media (min-width: 768px) {
      .Header ul {
        background: transparent;
        margin: 0rem;
        width: auto; } }
  @media (min-width: 768px) {
    .Header li {
      margin-right: 2rem; } }

.Main {
  overflow: hidden; }
  .Main section p {
    padding: 5rem; }
  .Main section:nth-child(1) p {
    padding: 0; }
  .Main-section1 {
    background: linear-gradient(-14deg, #ebeeff 0%, #ebeeff 50%, white 50%);
    min-height: calc(100vh - 88px);
    justify-content: space-between;
    align-items: center; }
    @media (min-width: 1600px) {
      .Main-section1 {
        min-height: 80vh; } }
    .Main-section1-description.col-md-6 {
      padding-top: 2rem;
      padding-right: 5rem;
      padding-left: 5rem;
      padding-bottom: 5rem; }
      @media (min-width: 768px) {
        .Main-section1-description.col-md-6 {
          padding: 5rem;
          margin-bottom: auto; } }
  .Main-welcome {
    display: flex;
    align-items: center; }
    .Main-welcome-img {
      width: 100%;
      max-height: 730px; }
  .Main-tech {
    width: 1rem;
    margin-right: 1rem; }
  .Main #hunt {
    color: #656da2;
    background: #ebeeff; }
    .Main #hunt img {
      width: 100%; }
    .Main #hunt > div > div:nth-child(1) {
      font-size: 0; }
  .Main #bench {
    background: linear-gradient(166deg, #ebeeff 28%, white 0); }
  .Main #steps .container > div {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    flex-direction: column;
    width: 100%; }
    .Main #steps .container > div > img, .Main #steps .container > div > div {
      width: 200px;
      margin: auto; }
    .Main #steps .container > div .steps-scan-img {
      max-height: 150px; }
  .Main #contact {
    margin-top: 4rem;
    width: 100%;
    padding: 10% 25%;
    background: #333976; }

@font-face {
  font-family: "spartan-extra-bold";
  src: url(/static/media/SpartanMB-Extra-Bold.71c21cc7.otf) format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 800; }

@font-face {
  font-family: "spartan-bold";
  src: url(/static/media/SpartanMB-Bold.bf49e47b.otf) format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 600; }

@font-face {
  font-family: "spartan-semi-bold";
  src: url(/static/media/SpartanMB-SemiBold.996a9ca7.otf) format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 600; }

@font-face {
  font-family: "spartan";
  src: url(/static/media/SpartanMB-Regular.6e3ac162.otf) format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 400; }

.spartan, body, button, input, textarea {
  font-family: 'spartan', "Helvetica Neue", sans-serif; }

.spartan-semi-bold {
  font-family: 'spartan-semi-bold', "Helvetica Neue", sans-serif; }

.spartan-bold, a {
  font-family: 'spartan-bold', "Helvetica Neue", sans-serif; }

.spartan-extra-bold, h1 {
  font-family: 'spartan-extra-bold', "Helvetica Neue", sans-serif; }

img {
  max-width: 100%; }

.m-0 {
  margin: 0rem !important; }

.m-1 {
  margin: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.m-6 {
  margin: 5rem !important; }

.mt-0 {
  margin-top: 0rem !important; }

.mt-1 {
  margin-top: 0.25rem !important; }

.mt-2 {
  margin-top: 0.5rem !important; }

.mt-3 {
  margin-top: 1rem !important; }

.mt-4 {
  margin-top: 1.5rem !important; }

.mt-5 {
  margin-top: 3rem !important; }

.mt-6 {
  margin-top: 5rem !important; }

.mr-0 {
  margin-right: 0rem !important; }

.mr-1 {
  margin-right: 0.25rem !important; }

.mr-2 {
  margin-right: 0.5rem !important; }

.mr-3 {
  margin-right: 1rem !important; }

.mr-4 {
  margin-right: 1.5rem !important; }

.mr-5 {
  margin-right: 3rem !important; }

.mr-6 {
  margin-right: 5rem !important; }

.mb-0 {
  margin-bottom: 0rem !important; }

.mb-1 {
  margin-bottom: 0.25rem !important; }

.mb-2 {
  margin-bottom: 0.5rem !important; }

.mb-3 {
  margin-bottom: 1rem !important; }

.mb-4 {
  margin-bottom: 1.5rem !important; }

.mb-5 {
  margin-bottom: 3rem !important; }

.mb-6 {
  margin-bottom: 5rem !important; }

.ml-0 {
  margin-left: 0rem !important; }

.ml-1 {
  margin-left: 0.25rem !important; }

.ml-2 {
  margin-left: 0.5rem !important; }

.ml-3 {
  margin-left: 1rem !important; }

.ml-4 {
  margin-left: 1.5rem !important; }

.ml-5 {
  margin-left: 3rem !important; }

.ml-6 {
  margin-left: 5rem !important; }

.p-0 {
  padding: 0rem !important; }

.p-1 {
  padding: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.p-6 {
  padding: 5rem !important; }

.pt-0 {
  padding-top: 0rem !important; }

.pt-1 {
  padding-top: 0.25rem !important; }

.pt-2 {
  padding-top: 0.5rem !important; }

.pt-3 {
  padding-top: 1rem !important; }

.pt-4 {
  padding-top: 1.5rem !important; }

.pt-5 {
  padding-top: 3rem !important; }

.pt-6 {
  padding-top: 5rem !important; }

.pr-0 {
  padding-right: 0rem !important; }

.pr-1 {
  padding-right: 0.25rem !important; }

.pr-2 {
  padding-right: 0.5rem !important; }

.pr-3 {
  padding-right: 1rem !important; }

.pr-4 {
  padding-right: 1.5rem !important; }

.pr-5 {
  padding-right: 3rem !important; }

.pr-6 {
  padding-right: 5rem !important; }

.pb-0 {
  padding-bottom: 0rem !important; }

.pb-1 {
  padding-bottom: 0.25rem !important; }

.pb-2 {
  padding-bottom: 0.5rem !important; }

.pb-3 {
  padding-bottom: 1rem !important; }

.pb-4 {
  padding-bottom: 1.5rem !important; }

.pb-5 {
  padding-bottom: 3rem !important; }

.pb-6 {
  padding-bottom: 5rem !important; }

.pl-0 {
  padding-left: 0rem !important; }

.pl-1 {
  padding-left: 0.25rem !important; }

.pl-2 {
  padding-left: 0.5rem !important; }

.pl-3 {
  padding-left: 1rem !important; }

.pl-4 {
  padding-left: 1.5rem !important; }

.pl-5 {
  padding-left: 3rem !important; }

.pl-6 {
  padding-left: 5rem !important; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

div[class^='col-'], div[class*=' col-'] {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

@media (min-width: 768px) {
  .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%; } }

@media (min-width: 768px) {
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; } }

.m-auto {
  margin: auto; }

.p-relative {
  position: relative; }

.container {
  width: 100%;
  margin: auto;
  max-width: 1600px; }

.d-flex {
  display: flex; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.flex-fill {
  flex: 1 1 auto; }

.align-item-center {
  align-items: center; }

@media (min-width: 768px) {
  .reverse-md {
    flex-direction: row-reverse; } }

.table-like .row {
  align-items: center;
  border-bottom: 1px solid #f0f3ff; }

.grid {
  display: grid; }
  .grid-element {
    background-size: cover;
    background-position: center; }

.text-align-left {
  text-align: left; }

.text-align-right {
  text-align: right; }

.w-100 {
  width: 100%; }

.w-50 {
  width: 50%; }

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.pointer {
  cursor: pointer; }

.form-control {
  display: block;
  margin-bottom: 1rem;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  -webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out; }

input.form-control {
  height: calc(1.5em + .75rem + 2px); }

.text-primary-dark {
  color: #333976; }

.text-primary-light {
  color: #acb0c8; }

.btn {
  cursor: pointer;
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 50px;
  -webkit-transition: background .15s ease-in-out, color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition: background .15s ease-in-out, color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out; }

.btn-block {
  display: block;
  width: 100%; }

.btn-lg {
  padding: .75rem 1.5rem; }

.btn-primary {
  color: white;
  background: -webkit-gradient(linear, left bottom, left top, from(#364ddf), to(#5265e0));
  background: linear-gradient(0deg, #364ddf 0%, #5265e0 100%); }
  .btn-primary:hover {
    color: #364ddf;
    background: transparent;
    border: 1px solid #364ddf; }

.btn-white {
  color: white;
  border: 1px solid white; }
  .btn-white:hover {
    color: #364ddf;
    background: white;
    border: 1px solid white; }

@-webkit-keyframes fadeIn {
  0% {
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; } }

.fade-in {
  opacity: 0;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-name: fadeIn;
          animation-name: fadeIn;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards; }

.delay-0 {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s; }

.delay-1 {
  -webkit-animation-delay: 1s;
          animation-delay: 1s; }

.delay-2 {
  -webkit-animation-delay: 1.5s;
          animation-delay: 1.5s; }

.delay-3 {
  -webkit-animation-delay: 2s;
          animation-delay: 2s; }

.delay-4 {
  -webkit-animation-delay: 2.5s;
          animation-delay: 2.5s; }

.delay-5 {
  -webkit-animation-delay: 3s;
          animation-delay: 3s; }

.delay-6 {
  -webkit-animation-delay: 3.5s;
          animation-delay: 3.5s; }

.delay-7 {
  -webkit-animation-delay: 4s;
          animation-delay: 4s; }

.delay-8 {
  -webkit-animation-delay: 4.5s;
          animation-delay: 4.5s; }

.delay-9 {
  -webkit-animation-delay: 5s;
          animation-delay: 5s; }

.delay-10 {
  -webkit-animation-delay: 5.5s;
          animation-delay: 5.5s; }

.delay-11 {
  -webkit-animation-delay: 6s;
          animation-delay: 6s; }

.delay-12 {
  -webkit-animation-delay: 6.5s;
          animation-delay: 6.5s; }

.delay-13 {
  -webkit-animation-delay: 7s;
          animation-delay: 7s; }

.delay-14 {
  -webkit-animation-delay: 7.5s;
          animation-delay: 7.5s; }

* {
  box-sizing: border-box; }

body {
  color: #acb0c8;
  min-height: 100vh; }

a {
  color: #333976;
  text-decoration: none; }

h1 {
  color: #333976;
  font-size: 2.3rem;
  line-height: 3.3rem; }

h2 {
  color: #333976; }

