@import "../variables.scss";

.btn{
  cursor: pointer;
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 50px;
  transition:  background .15s ease-in-out, color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-lg {
  padding: .75rem 1.5rem;
}

.btn-primary {
   color: white;
   background: linear-gradient(0deg, $primary 0%, #5265e0 100%);
   &:hover{
     color: $primary;
     background: transparent;
     border: 1px solid $primary;
   }
 }

.btn-white {
  color: white;
  border: 1px solid white;
  &:hover{
    color: $primary;
    background: white;
    border: 1px solid white;
  }
}
